import React from "react";
// Customizable Area Start
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Snackbar, Typography, styled, withStyles } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CustomButton, CustomInputlabelEmail, CustomTextField, SignInInputLabel } from "../../email-account-login/src/CustomButton";
import "./emailAccountRegistration.css";
// Customizable Area End

// Customizable Area Start
import { corner_img, logo, main, verifiedIcon, visibilityIcon, visibilityOffIcon } from "./assets";
import VerifyOtpModel from "../../../components/src/VerifyOtpModel";
import Loader from "../../../components/src/Loader.web";
import CustomNotification from "../../../components/src/CustomNotification";
export const configJSON = require("./config");

const CustomCSSButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 600,
    width: 163,
    height: 48,
  }
});

const GreenCheckbox = withStyles({
  root: {
    color: '#6d89af',
    '&$checked': {
      color: '#f5c42c',
    },
    '& .MuiSvgIcon-root':
      { fontSize: 20 }
  },
  checked: {}
})(Checkbox);

const CustomTextFieldCSS = withStyles({
  root: {
    backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E3EAF4",
      },
      "&:hover fieldset": {
        borderColor: "#f5c42c",
      },
      "&.Mui-focused fieldset": {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",
      },
      fontWeight: 400,
      borderRadius: "14px",
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        color: '#17181D',
        lineHeight: '19px',
        fontWeight: 600,
        fontSize: '14px',
        borderColor: '#e3eaf4',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      fontWeight: 500,
      fontFamily: 'Urbanist',
      color: '#6d89af',
    },
  }
})(CustomTextField);
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderErrorMessage(type: boolean, message: string) {
    return <>
      {type ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{message}</Typography> : null}
    </>
  }

  renderSignUPFieldAfterVerified() {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="fullName" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Full Name</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomTextFieldCSS data-test-id="fullNameInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your full name"
          value={this.state.fullName}
          onChange={(e: any) => this.handleChangeFullNameInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("fullName");
          }}
          onBlur={() => {
            this.handleInputBlur("fullName");
          }}
        />
        {this.renderErrorMessage(this.state.fullNameError.require, "Full name is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="emailAddress" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Email ID</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomTextFieldCSS data-test-id="emailAddressInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your email ID"
          value={this.state.email}
          onChange={(e: any) => this.handleChangeEmailInput(e)}
          className="email_input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {this.state.isEmailVerified
                  ? <img src={verifiedIcon} />
                  : <Typography style={{ color: '#f5c42c', fontFamily: 'Urbanist', fontSize: '14px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px', cursor: 'pointer' }} onClick={this.handleVerifyEmail}>Verify</Typography>}
              </InputAdornment>
            ),
          }}
          onFocus={() => {
            this.handleInputFocus("emailAddress");
          }}
          onBlur={() => {
            this.handleInputBlur("emailAddress");
          }}
        />
        {this.renderErrorMessage(this.state.emailError.invalid, "Email ID is invalid")}
        {this.renderErrorMessage(this.state.emailError.require, "Email ID is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="roleDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Your Role</CustomInputlabelEmail>
      </Grid>
      <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
        <Select
          data-test-id="role-select-dropdown"
          id="demo-simple-select"
          value={this.state.yourRole}
          label="Age"
          displayEmpty
          onChange={this.handleSelectRoleValue}
          onFocus={() => {
            this.handleInputFocus("roleDropdown");
          }}
          onBlur={() => {
            this.handleInputBlur("roleDropdown");
          }}
        >
          <MenuItem value="">Select your role</MenuItem>
          {this.state.rolesData.map((role: any) => {
            return <MenuItem value={role.id}>{role.name}</MenuItem>
          })}
        </Select>
        {this.renderErrorMessage(this.state.roleError.require, "Your role is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="deptDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Select your department</CustomInputlabelEmail>
      </Grid>
      <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
        <Select
          data-test-id="dept-select-dropdown"
          id="demo-simple-select"
          value={this.state.selectedDept}
          label="Age"
          displayEmpty
          onChange={this.handleSelectDeptValue}
          onFocus={() => {
            this.handleInputFocus("deptDropdown");
          }}
          onBlur={() => {
            this.handleInputBlur("deptDropdown");
          }}
        >
          <MenuItem value="">Select your department </MenuItem>
          {this.state.departmentsData.map((dept: any) => {
            return <MenuItem value={dept.id}>{dept.department_name}</MenuItem>
          })}
        </Select>
        {this.renderErrorMessage(this.state.deptError.require, "Department is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="setPassword" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Set Password</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomTextFieldCSS data-test-id="setPasswordInput" type={this.state.isPasswordVisible ? 'text' : "password"} id="outlined-basic" variant="outlined" placeholder="Enter your password"
          value={this.state.password}
          onChange={(e: any) => this.handleChangeSetPasswordInput(e)}
          className="email_input"
          InputProps={{
            autoComplete: 'new-password',
            endAdornment: (
              <InputAdornment position="end" >
                <img style={{ cursor: 'pointer' }} onClick={() => this.handleVisibilityChange("password")} src={this.state.isPasswordVisible ? visibilityOffIcon : visibilityIcon} />
              </InputAdornment>
            ),
          }}
          onFocus={() => {
            this.handleInputFocus("setPassword");
          }}
          onBlur={() => {
            this.handleInputBlur("setPassword");
          }}
        />
        {this.renderErrorMessage(this.state.setPassError.invalid, "You have entered an invalid password")}
        {this.renderErrorMessage(this.state.setPassError.require, "Password is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="confirmPassword" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Confirm Password</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomTextFieldCSS data-test-id="confirmPasswordInput" type={this.state.isConfirmPasswordVisible ? 'text' : "password"} id="outlined-basic" variant="outlined" placeholder="Enter your confirm password"
          value={this.state.reTypePassword}
          onChange={(e: any) => this.handleChangeConfirmPasswordInput(e)}
          className="email_input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <img style={{ cursor: 'pointer' }} onClick={() => this.handleVisibilityChange("confirmPassword")} src={this.state.isConfirmPasswordVisible ? visibilityOffIcon : visibilityIcon} />
              </InputAdornment>
            ),
          }}
          onFocus={() => {
            this.handleInputFocus("confirmPassword");
          }}
          onBlur={() => {
            this.handleInputBlur("confirmPassword");
          }}
        />
        {this.renderErrorMessage(this.state.confirmPassError.match, "Password and confirm password did not match")}
        {this.renderErrorMessage(this.state.confirmPassError.require, "Confirm password is required")}
      </Grid>
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    return (<>
      <Loader loading={this.state.isLoading} />
      <Grid container style={{overflow:'hidden'}}>
        <Grid xs={12} item sm={12} md={6} lg={6} xl={6} style={{ position: 'relative', overflow: 'hidden' }}>
          <img alt="Main" src={main} style={{  width: '50%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              } as React.CSSProperties} />
        </Grid>
        <Grid xs={12} item sm={12} md={6} lg={6} xl={6}   style={{ overflowY: 'auto', height: '100vh', position: 'relative' }}>
          <Box className="main_box">
            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} xs={12} item sm={12} md={12} lg={12}>
              <Box data-test-id="signVendorBack" onClick={() => { this.props.navigation && this.props.navigation.navigate("LandingPage") }}>
                <IconButton style={{ paddingLeft: "0px" }}>
                  <ArrowBackIcon htmlColor="#17181D" style={{ height: "24px", width: "24px" } as React.CSSProperties} />
                </IconButton>
                <span style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, lineHeight: "18px", textDecoration: "underline", letterSpacing: "0.4923077px" }}>
                  Back to Login
                </span>
              </Box>
              <Box>
                <img src={logo} alt="Main Image" style={{ marginBottom: "28px", width: "70px" }} />
              </Box>
            </Grid>
            <img alt="Corner Image" src={corner_img} className="corner_img" />
            <Grid xs={12} item sm={12} md={12} lg={12}>
              <label
                className="welcome_back_text"
                style={{ fontFamily: "Urbanist", fontSize: "32px" }}
              >
                Sign up as Vendor
              </label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SignInInputLabel
                style={{
                  marginTop: "1%",
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontFamily: "Urbanist",
                  color: "#6D89AF",
                  fontWeight: 600,
                  maxWidth: "100%",
                  width: "100%",
                  paddingBottom: "14px"
                }}
              >
                Create your account as a vendor and explore new & highly paid jobs.
              </SignInInputLabel>
            </Grid>
            <Grid item style={{ width: '100%', height: 0, border: '0.2px solid #e3eaf4' } as React.CSSProperties} />
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomInputlabelEmail className="email_lable" id="phonenumber" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Phone Number</CustomInputlabelEmail>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomTextFieldCSS data-test-id="phoneNumberInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your phone number"
                  disabled={this.state.isPhoneVerified}
                  InputProps={{
                    endAdornment: this.state.isPhoneVerified ? (
                      <InputAdornment position="end" >
                        <img src={verifiedIcon} />
                      </InputAdornment>
                    ) : null,
                    startAdornment: (
                      <InputAdornment position="start" style={{ cursor: 'hand' }} className="phone_input_adornment_box">
                        <CountryCodeSelector
                          navigation={navigation}
                          id={"CountryCodeSelector"}
                          style={{ width: "80px" }}
                          disable={false}
                          allowPropChange={true}
                          value={this.state.countryCodeSelected}
                          getCountryName={this.getCountryName}
                        />
                        <Typography style={{ marginLeft: '9px', color: '#17181d' }}> +{this.state.countryCodeSelected}</Typography>
                        <Box style={{ borderLeft: '1px solid #6d89af', height: '23px', marginLeft: '12px' } as React.CSSProperties}></Box>
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.phone}
                  onChange={(e: any) => this.handlePhoneNumberInput(e)}
                  className="email_input"
                  onFocus={() => {
                    this.handleInputFocus("phonenumber");
                  }}
                  onBlur={() => {
                    this.handleInputBlur("phonenumber");
                  }}
                />
                {this.renderErrorMessage(this.state.phoneNumberError.invalid, "Phone number is invalid")}
                {this.renderErrorMessage(this.state.phoneNumberError.require, "Phone number is required")}
              </Grid>

              {this.state.isPhoneVerified ? this.renderSignUPFieldAfterVerified() : null}

              <Grid>
                <FormControlLabel style={{ marginTop: 12 }}
                  control={<GreenCheckbox checked={this.state.isTnc} disabled={this.state.isPhoneVerified} onChange={this.handleChange} name="tnc" />}
                  label={<Typography style={{
                    color: '#18272a',
                    fontFamily: 'Urbanist',
                    fontSize: '13px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0.37142858px',
                    cursor: "pointer"
                  }}>Agree to the <span style={{ color: '#f5c42c', textDecoration: 'underline' }} onClick={this.handleTncNavigation}>Terms & Conditions</span> of ai pictures.</Typography>}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className="get_otp_main_grid">
                {this.state.isPhoneVerified ?
                  <Grid item style={{ width: 202, height: 48 } as React.CSSProperties} className="button_box">
                    <CustomCSSButtonBox data-test-id="sign_up_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={this.handleSignUpNow}>
                      Sign Up Now
                    </CustomCSSButtonBox>
                  </Grid>
                  :
                  <Grid item style={{ width: 163, height: 48 } as React.CSSProperties} className="button_box">
                    <CustomCSSButtonBox data-test-id="get_otp_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={this.handleGetOTPAPICall}>
                      Get OTP
                    </CustomCSSButtonBox>
                  </Grid>}
              </Grid>
            </Grid>
          </Box >
        </Grid >
      </Grid >
      {this.state.isModelOpen ? <VerifyOtpModel type={this.state.isPhoneVerified ? "email" : "phone"} open={this.state.isModelOpen} handleClose={this.handleClose} disabledResend={this.state.isDisabled} setIsDisabled={(val:boolean)=> this.setState({isDisabled:val})} verifyOtpAPICall={this.verifyOtpAPICall} phoneNumber={this.state.isPhoneVerified ? this.state.email : `+${this.state.countryCodeSelected}-${this.state.phone}`} requestAgainAPICall={this.getOtpApiCall} /> : null}
      <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
    </>
    );
    // Customizable Area End
  }
}