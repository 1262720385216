import React, { useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText, Avatar, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import Badge from "@material-ui/core/Badge";
import { checkedImage } from "./assets";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {
    withStyles,
    createStyles,

} from "@material-ui/core/styles"
interface MyProps {
    data: any;
    HandleCommentHold:any;
    HoldCommentvalue:any;
    getHoldedbuttonapi:any
    handleCloseHold: any

}



const useStyles = makeStyles({
    ParentMain: {

        //marginTop: '20px'


    },
    HeadingRateandReview: {
        fontSize: '20px',
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '10px'
    },
    AiIcon: {
        display: 'flex',
        justifyContent: "center",
        paddingTop: '20px'

    },
    AiText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.225px',
        paddingBottom: '2px',
        display: 'flex',
        justifyContent: "center"
    },
    ratingDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '200px'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "160px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
    holdTextarea: {
        '&:focus[data-focusvisible-polyfill]': {
            outline: '2px solid rgb(245, 196, 44)'
        }
    }
});
export const StyledBadge = withStyles(() =>
    createStyles({
        badge: {
            color: "#44b700",
            "&::after": {
                position: "absolute",
                right: -3,
                top: 13,
                padding: "0 4px",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                content: '""'
            }
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0
            }
        }
    })
)(Badge);


export default function RateNowCard(props: MyProps) {
    const { data,HandleCommentHold,HoldCommentvalue,getHoldedbuttonapi, handleCloseHold} = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);




    return (

        <Box className={classes.ParentMain}>
           
           
            
            <Box style={{  paddingLeft: '30px',paddingTop:'30px',paddingBottom:'10px', fontSize: "15px" ,fontWeight:700,fontFamily:'urbanist',}}>
                Write Comment
            </Box>
          <Box style={{display:'flex',justifyContent:'center'}}>
            <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="enter your comments."
                style={{height:'70px',width:'400',paddingLeft:"15px",paddingTop:'5px',backgroundColor:"#ffffff",marginLeft:"15px",display:'flex',justifyContent:'center',borderRadius:'12px',border:  '0.5px solid #f5c42c'} as React.CSSProperties}
                value={HoldCommentvalue}  
                 onChange={HandleCommentHold}
                 className={classes.holdTextarea}
            />
            </Box>
            <Box style={{display:'flex',justifyContent:'center',paddingTop:'5px'}}>
            <button className={classes.submitButton} 
             onClick={() => { getHoldedbuttonapi(); handleCloseHold() }}
            >
                            Submit
                        </button>
                        </Box>


        </Box>

    );
}
const webStyle = {

    avatar: {
        border: "3px solid #f5c42c",
        width: 62,
        height: 62,
        marginRight: 1
    },

}