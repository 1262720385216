import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import exp from 'constants';
interface MyProps {
    data:any
    experience:any
    department:any
    RoleType:any
    cityname:any
    handleOptionChange:any
    stateindex:any
    handleOptionChangedepartment:any;
    handleOptionChangecity:any;
    handleOptionChangeroletype:any;
    cityvalue:string;
    departmentvalue:string;
    roletypevalue:string;
    handleClose:any;
    sortApiCallForAllProduction:any;
    ClearDataValues:any;
}
const TableGrid = styled(Grid)({
    '& .MuiGrid-root::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiGrid-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        "@media screen and (max-width: 500px)": {
        
            paddingLeft:'10px'
        }
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})
const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: 'black',
        fontWeight: 700,
        fontSize: '14px',
        
        overflowY: 'scroll'
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
        color: '#F5C42C'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#f5c42c',

    },
    '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '&.MuiTypography-body1': {


        fontWeight: 700,

    }
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});
const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const CustomFormControlLabel1 = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px",
        
        
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
        color: '#F5C42C'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#F5C42C'
    },
    '& .MuiIconButton-colorSecondary': {
        //color: '#f50057',
    }

});


const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {

        width: 200,
    },
    ParentDiv: {

        border: '10px 1px solid #d8d8d8',
        textAlign: 'center',
        minHeight:'474px',
        "@media screen and (max-width: 500px)": {
        
            width: '400px',
            marginRight: "0px",
        
        }


    }
    ,
    filterDiv: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Urbanist"
    }
    ,
    HeaderDiv: {
        fontSize: "20px",
        textAlign: 'center',
        fontWeight: 700,
        borderBottom: "1px solid #E3EAF4",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: 'flex',
        justifyContent: 'end',
        gap: '260px',
        paddingRight: '20px'


    },
    MainDiv: {
        display: 'flex',
        borderRight: "1px solid #d8d8d8",
        borderBottom: "1px solid #d8d8d8",
        // height:"112%",
    },
    SideDiv: {
        minWidth: "140px",
        borderRight: "1px solid #d8d8d8",
        marginRight: '10px',
        backgroundColor: '#f8fafe',

        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }
    },
    main: {
        paddingBottom: "40px",
        paddingRight: '7px',
        minHeight:'288px',
        display:"flex", 
        flexWrap:"wrap"
    },
    stateDiv: () => ({
        

    }),
    SearchDiv: {
        display: "flex",
        border: '1px solid  #6D89AF',
        width: "286px",
        borderRadius: "35px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        overflowX: 'hidden',
        height: "44px",
        marginBottom: '6px',
        "@media screen and (max-width: 500px)": {
            minWidth: "100px",
            width: '216px',
            marginRight: "0px",
            height:'40px'
        }
    },
    TableDiv: {
        marginTop: "16px",
        display: 'flex',
        gridRowGap: '10px'
    },
    FooterDiv: {
        paddingTop: "16px",
        paddingBottom: '16px',
        justifyContent: 'end',
        paddingRight: '10px',
        display: 'flex',
        gap: '5px'

    },
    list1: {
        display: "flex",
        gap: '10px'
    },
    list2: {
        display: "flex",
        gap: '10px'
    },
    list3: {
        display: "flex",
        gap: '10px'
    },
    list4: {
        display: "flex",
        gap: '10px'
    },
    button1: {
        border: '1px solid #d8d8d8',
        height: '43px',
        width: '156px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    },
    button2: {
        border: '1px solid #d8d8d8',
        height: '45px',
        width: '171px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    }
    ,
    cityDiv: {
        paddingTop: "10px",
        "@media screen and (max-width: 500px)": {
        
            display:'flex',
            flexDirection:'column'
        }
    },

    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "165px",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "16px",
        paddingTop: "10px",
        paddingBottom: "14px",
        fontFamily: "urbanist",
        border: '1px solid #17181d'



    },
    button4: {
        border: '1px solid #d8d8d8',
        width: '156px',
        fontWeight: 600,
        fontSize: '15px',
        borderRadius: "15px",
        height: "43px",
        color: "white",
        backgroundColor: 'black'



    },
    ParentMain: {
        display: 'flex',
        justifyContent: 'center',
        //marginTop: '20px'
    }
    ,
    activeText: {
        color: '#f5c42c',
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "14px",
        fontFamily: "Urbanist",
        lineHeight: "19px",
        backgroundColor: "#ffffff",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        },
        "& .MuiTypography-displayBlock":{
            fontWeight:700
        }

    },
    buttonGroupDiv: {
        paddingTop: "10px",
        overflowY: 'auto',
        height: '388px',
        width:"500px"
    },
    inactiveText: {
        color: "#17181d",
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#f8fafe",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        },
        "& .MuiTypography-displayBlock":{
            fontWeight:700
        }
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "165px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "16px",
        paddingTop: "10px",
        paddingBottom: "14px",
        fontFamily: "urbanist",
    },
    selectedButton: {
        border: '1px solid #f5c42c',
        borderRadius: '12px'
    },
    unselectedButton: {
        borderRadius: '12px'
    },
    searchicon: {
        marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "14px",
        paddingTop: '14px',
    }

});



export default function Sortfunctionality(props: MyProps) {
    const {data,experience,sortApiCallForAllProduction,ClearDataValues,department,RoleType,cityname,stateindex,handleOptionChange,handleOptionChangecity,handleOptionChangedepartment,handleOptionChangeroletype,cityvalue,handleClose,roletypevalue,departmentvalue} = props;

    const classes = useStyles();
    //const [selectedDate, setSelectedDate] = useState(''); // State to store the selected date
    const [state, setState] = useState(true);
    const [city, setCity] = useState(false)
    const [status, setStatus] = useState(false)
    const [startDate, setStartDate] = useState(false)
    const handleclick = () => {
        setState(true)
        setCity(false)
        setStatus(false)
        setStartDate(false)
    };
    const handleclick1 = () => {
        setState(false)
        setCity(true)
        setStatus(false)
        setStartDate(false)
    };
    const handleclick2 = () => {
        setState(false)
        setCity(false)
        setStatus(true)
        setStartDate(false)
    };
    const handleclick3 = () => {
        setStartDate(true);
        setCity(false);
        setState(false);
        setStatus(false)
    }
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };
    
    


    return (
        <Grid className={classes.ParentMain}>
            <Grid className={classes.ParentDiv}>
                <Grid className={classes.HeaderDiv}>
                    <Grid className={classes.filterDiv}>
                        Filter
                    </Grid>
                    <Grid className={classes.filterDiv}>
                        <CloseIcon onClick={handleClose} />
                    </Grid>
                </Grid>
                <Grid className={classes.MainDiv}>
                    <Grid className={classes.SideDiv}>
                        <List style={{ fontSize: '14px' }}>
                            <ListItem className={state ? classes.activeText : classes.inactiveText} onClick={handleclick}>
                                <ListItemText primary="Experience" />
                            </ListItem>
                            <Divider />
                            <ListItem className={city ? classes.activeText : classes.inactiveText} onClick={handleclick1}>
                                <ListItemText primary="Department" />
                            </ListItem>
                            <Divider />
                            <ListItem className={startDate ? classes.activeText : classes.inactiveText} onClick={handleclick3}>
                                <ListItemText primary="Role Type" />
                            </ListItem>
                            <Divider />
                            <ListItem className={status ? classes.activeText : classes.inactiveText} onClick={handleclick2}>
                                <ListItemText primary="City" />
                            </ListItem>
                            <Divider />
                        </List>
                    </Grid>

                    <Box
                     className={classes.main}
                      >
                        <Box
                         className={classes.TableDiv}
                        >
                            {state === true ? (
                                <Grid>
                                    <Grid style={{ paddingTop: "16px" }}
                                     className={classes.buttonGroupDiv}
                                     >
                                        <RadioGroup value={selectedValue} onChange={handleChange}>
                                            <Grid
                                            style={{display:"grid", gridTemplateColumns:"auto auto", gap:10}}
                                              >
                                            {experience.map((state: any, index: number) => (
                                                  
                                                        <Button
                                                            style={{
                                                                border: stateindex === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                                borderRadius: '12px',width:"15rem"
                                                            }}
                                                            onClick={() => handleOptionChange(index, state)}
                                                        >
                                                            <CustomFormControlLabel
                                                                value={state}
                                                                label={state}
                                                                control={<Radio checked={stateindex === state} />}
                                                                labelPlacement="start"
                                                                style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                            />
                                                        </Button>
                                                ))}
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            ) : city === true ? (
                                <Grid>
                                   
                                    <Grid style={{ paddingTop: "16px" }} className={classes.buttonGroupDiv}>
                                        <RadioGroup value={selectedValue} onChange={handleChange}>
                                            <Grid   style={{display:"grid", gridTemplateColumns:"auto auto", gap:10}}>
                                            {department.map((state: any, index: number) => (
                                                        <Button
                                                          
                                                            style={{
                                                                border:departmentvalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                                borderRadius: '12px'
                                                            }}
                                                            onClick={() => handleOptionChangedepartment(index, state)}
                                                        
                                                        >
                                                            <CustomFormControlLabel
                                                                value={state}
                                                                label={state}
                                                                control={<Radio checked={departmentvalue === state} />}
                                                                labelPlacement="start"
                                                                style={{ lineHeight: "17px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                            />
                                                        </Button>
                                                   

                                                ))}
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>

                            ) : startDate == true ? (


                                <Grid>
                                   
                                    <Grid style={{ paddingTop: "16px" }} className={classes.buttonGroupDiv}>
                                        <RadioGroup value={selectedValue} onChange={handleChange}>
                                            <Grid  style={{display:"grid", gridTemplateColumns:"auto auto", gap:10}}>
                                            {RoleType.map((state: any, index: number) => (
                                                   
                                                        <Button
                                                           
                                                            style={{
                                                                border: roletypevalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                                borderRadius: '12px'
                                                            }}
                                                            onClick={() => handleOptionChangeroletype(index, state)}
                                                        
                                                        >
                                                            <CustomFormControlLabel
                                                                value={state}
                                                                label={state}
                                                                control={<Radio checked={roletypevalue === state} />}
                                                                labelPlacement="start"
                                                                style={{ lineHeight: "17px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                            />
                                                        </Button>
                                                   

                                                ))}
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>


                            ) : (
                                <Grid>
                                   
                                <Grid style={{ paddingTop: "16px" }} className={classes.buttonGroupDiv}>
                                    <RadioGroup value={selectedValue} onChange={handleChange}>
                                        <Grid  style={{display:"grid", gridTemplateColumns:"auto auto", gap:10}}>
                                        {cityname?.map((state: any, index: number) => (
                                                        <Button
                                                           
                                                            style={{
                                                                border: cityvalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                                borderRadius: '12px'
                                                            }}
                                                            onClick={() => handleOptionChangecity(index, state)}
                                                        
                                                        >
                                                            <CustomFormControlLabel
                                                                value={state}
                                                                label={state}
                                                                control={<Radio checked={cityvalue === state} />}
                                                                labelPlacement="start"
                                                                style={{ lineHeight: "17px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                            />
                                                        </Button>

                                                ))
                                                }
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                                    )



                            }

                                </Box>
                    </Box>

                </Grid>
                <Grid className={classes.FooterDiv}>
                    <button
                        className={classes.button3}  onClick={ClearDataValues} >
                        Clear All
                    </button>
                    <button
                        className={classes.submitButton}
                        onClick={sortApiCallForAllProduction}
                
                    >
                        Apply
                    </button>
                </Grid>
            </Grid>
        </Grid>

    );
}
